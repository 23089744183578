// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Form, KeyboardView, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useState,
  useQuery,
  useResponsive,
  MutationError,
  ResponsiveType,
} from '@supermove/hooks';
import {DocumentModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import DocumentV2ByCategory from '@shared/modules/Document/components/DocumentV2ByCategory';
import CompleteUserFlowRunStepDocumentForm, {
  CompleteUserFlowRunStepDocumentFormToFormType,
} from '@shared/modules/Document/forms/CompleteUserFlowRunStepDocumentForm';
import UpdateDocumentContentJsonForUserFlowRunDocument from '@shared/modules/Document/forms/UpdateDocumentContentJsonForUserFlowRunDocument';
import useCompleteUserFlowRunStepDocumentMutation from '@shared/modules/Document/hooks/useCompleteUserFlowRunStepDocumentMutation';
import useUpdateDocumentContentJsonForUserFlowRunDocumentMutation from '@shared/modules/Document/hooks/useUpdateDocumentContentJsonForUserFlowRunDocumentMutation';

const Container = Styled.View`
  flex: 1;
`;

const DocumentContainer = Styled.View<ResponsiveType>`
  border-width: 1;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  padding: ${({mobile}) => (mobile ? 10 : 20)}px;
`;

const ContentContainer = Styled.View<ResponsiveType>`
  width: ${({mobile}) => (mobile ? '100%' : '796px')};
  padding-horizontal: ${({mobile}) => (mobile ? 20 : 0)}px;
`;

const Footer = Styled.View`
  align-items: center;
  border-top-width: 1;
  border-color: ${colors.gray.border};
`;

const FooterContent = Styled.View<ResponsiveType>`
  width: ${({mobile}) => (mobile ? '100%' : '796px')};
  padding-horizontal: ${({mobile}) => (mobile ? 20 : 0)}px;
`;

const ErrorsText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.red.warning};
`;

type BaseProps<P extends object> = {
  HeaderComponent?: React.ComponentType<P>;
  headerComponentProps?: P;
  submitButtonText?: string;
  onSubmit: () => void;
  beforeSubmit?: (onSubmit: () => void) => void;
};

const ErrorsMessage = ({form}: {form: Form<CompleteUserFlowRunStepDocumentFormToFormType>}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <ErrorsText responsive={responsive}>{`${pluralize(
        'error',
        _.get(form.values, 'completeUserFlowRunStepDocumentForm.numberOfErrors'),
        true,
      )} found`}</ErrorsText>
      <Space width={20} />
    </React.Fragment>
  );
};

const CustomerDocumentV2SignContent = <P extends object>({
  document,
  beforeSubmit,
  onSubmit,
  submitButtonText,
  HeaderComponent,
  headerComponentProps,
}: BaseProps<P> & {document: DocumentModel}) => {
  const responsive = useResponsive();
  const completeUserFlowRunStepDocumentForm = CompleteUserFlowRunStepDocumentForm.new({
    document,
    step: document.step,
  });
  const {form, handleSubmit, submitting} = useCompleteUserFlowRunStepDocumentMutation({
    completeUserFlowRunStepDocumentForm,
    onSuccess: onSubmit,
    onError: (errors: MutationError[]) => {
      console.log({errors});
      form.setFieldValue('completeUserFlowRunStepDocumentForm.numberOfErrors', errors.length);
    },
  });

  const hasErrors = _.some(
    _.filter(
      _.get(form.errors, 'completeUserFlowRunStepDocumentForm.documentForm.documentItemForms'),
      (item) => !_.isNil(item),
    ),
  );

  return (
    <Container>
      <KeyboardView>
        <ScrollView
          alwaysBounceVertical={false}
          style={{flex: 1}}
          contentContainerStyle={{alignItems: 'center'}}
        >
          <ContentContainer {...responsive}>
            {HeaderComponent && <HeaderComponent {...(headerComponentProps as P)} />}
            <Space height={10} />
            <DocumentContainer {...responsive}>
              <DocumentV2ByCategory
                document={document}
                form={form}
                field={'completeUserFlowRunStepDocumentForm'}
                isEditable
              />
            </DocumentContainer>
          </ContentContainer>
          <Space height={24} />
        </ScrollView>
      </KeyboardView>
      <Footer>
        <FooterContent {...responsive}>
          <Space height={16} />
          {hasErrors && (
            <React.Fragment>
              <ErrorsMessage form={form} />
              <Space height={8} />
            </React.Fragment>
          )}
          <Button
            isWidthOfContainer
            text={submitButtonText}
            isSubmitting={submitting}
            onPress={() => beforeSubmit?.(handleSubmit)}
          />
          <Space height={16} />
        </FooterContent>
      </Footer>
    </Container>
  );
};

const CustomerDocumentV2SignQuery = <P extends object>({
  documentUuid,
  beforeSubmit,
  onSubmit,
  submitButtonText,
  HeaderComponent,
  headerComponentProps,
}: BaseProps<P> & {documentUuid: string}) => {
  const {loading, data} = useQuery(CustomerDocumentV2Sign.query, {
    fetchPolicy: 'network-only',
    variables: {
      documentUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          <CustomerDocumentV2SignContent
            document={data.document}
            beforeSubmit={beforeSubmit}
            onSubmit={onSubmit}
            submitButtonText={submitButtonText}
            HeaderComponent={HeaderComponent}
            headerComponentProps={headerComponentProps}
          />
        );
      }}
    </Loading>
  );
};

const CustomerDocumentV2Sign = <P extends object>({
  documentUuid,
  beforeSubmit,
  onSubmit,
  submitButtonText,
  HeaderComponent,
  headerComponentProps,
}: BaseProps<P> & {documentUuid: string}) => {
  const [isMutationComplete, setIsMutationComplete] = useState(false);
  const {submitting, handleSubmit} = useUpdateDocumentContentJsonForUserFlowRunDocumentMutation({
    updateDocumentContentJsonForUserFlowRunDocumentForm:
      UpdateDocumentContentJsonForUserFlowRunDocument.new({
        documentUuid,
      }),
    onSuccess: () => {
      setIsMutationComplete(true);
    },
    onError: (errors: MutationError[]) => {
      console.log({errors});
      setIsMutationComplete(true);
    },
  });

  // Updating the document content JSON before displaying ensures that all variables are accurate.
  // This is necessary for custom/dynamic variables that can change by user input after being generated.
  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <Loading loading={submitting || !isMutationComplete} as={PageLoadingIndicator}>
      {() => {
        return (
          <CustomerDocumentV2SignQuery
            documentUuid={documentUuid}
            beforeSubmit={beforeSubmit}
            onSubmit={onSubmit}
            submitButtonText={submitButtonText}
            HeaderComponent={HeaderComponent}
            headerComponentProps={headerComponentProps}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerDocumentV2Sign.query = gql`
  ${CompleteUserFlowRunStepDocumentForm.new.fragment}
  ${DocumentV2ByCategory.fragment}

  query CustomerDocumentV2Sign($documentUuid: String!) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      step
      ...CompleteUserFlowRunStepDocumentForm_new
      ...DocumentV2ByCategory
    }
  }
`;

export default CustomerDocumentV2Sign;
