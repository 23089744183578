// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  padding-top: 40px;
  padding-horizontal: 50px;
`;

const Title = Styled.H2`
  margin-top: 40px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  color: ${colors.gray.primary};
`;

const DocumentFlowRunNotFoundPageContent = () => (
  <Container>
    <Title>Link not found</Title>
    <Subtitle>If you believe this is an error, please contact us.</Subtitle>
  </Container>
);

export default DocumentFlowRunNotFoundPageContent;
