// Libraries
import React from 'react';

// Supermove
import {ExternalLink, Icon, PageLoader, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigation, useResponsive} from '@supermove/hooks';
import {OrganizationModel, UserFlowRunModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import EmptyState from '@shared/design/components/EmptyState';

const PageContainer = Styled.View`
  padding: 32px;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const PhoneLink = ({organization}: {organization: OrganizationModel}) => {
  const responsive = useResponsive();
  return (
    <ExternalLink url={Phone.createUrl(organization.phoneNumber)}>
      <LinkText responsive={responsive}>{Phone.display(organization.phoneNumber)}</LinkText>
    </ExternalLink>
  );
};

const DocumentFlowRunExpiredPage = () => {
  const {navigator, params} = useNavigation();

  const {data, loading} = useQuery<{userFlowRun: UserFlowRunModel}>(
    DocumentFlowRunExpiredPage.query,
    {
      fetchPolicy: 'network-only',
      variables: {uuid: params.userFlowRunUuid},
    },
  );

  return (
    <PageLoader loading={loading} data={data}>
      {({loadedData}) => {
        const {userFlowRun} = loadedData;
        if (!userFlowRun.isExpired) {
          navigator.replace('DocumentFlowRunPage', {
            slug: params.slug,
            userFlowRunUuid: params.userFlowRunUuid,
          });
        }

        const {organization} = userFlowRun.project;
        const businessName = organization.businessName || 'us';

        return (
          <PageContainer>
            <EmptyState
              icon={Icon.ExclamationTriangle}
              iconColor={colors.orange.status}
              title={`This document flow has expired.`}
              MessageComponent={() => (
                <EmptyState.DescriptionText style={{textAlign: 'center'}}>
                  {`Please contact ${businessName} `}
                  {organization.phoneNumber !== '' && (
                    <React.Fragment>
                      {`at `}
                      <PhoneLink organization={organization} />
                      {` `}
                    </React.Fragment>
                  )}
                  {`for further assistance.`}
                </EmptyState.DescriptionText>
              )}
            />
          </PageContainer>
        );
      }}
    </PageLoader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFlowRunExpiredPage.query = gql`
  query DocumentFlowRunExpiredPage($uuid: String!) {
    ${gql.query}
    userFlowRun(uuid: $uuid) {
      id
      uuid
      isExpired
      project {
        id
        organization {
          id
          businessName
          phoneNumber
        }
      }
    }
  }
`;

export default DocumentFlowRunExpiredPage;
