// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {DocumentModel} from '@supermove/models';

// App
import UpdateDocumentContentJsonForUserFlowRunDocument, {
  UpdateDocumentContentJsonForUserFlowRunDocumentFormType,
} from '@shared/modules/Document/forms/UpdateDocumentContentJsonForUserFlowRunDocument';

const useUpdateDocumentContentJsonForUserFlowRunDocumentMutation = ({
  updateDocumentContentJsonForUserFlowRunDocumentForm,
  onSuccess,
  onError,
}: {
  updateDocumentContentJsonForUserFlowRunDocumentForm: UpdateDocumentContentJsonForUserFlowRunDocumentFormType;
  onSuccess: ({document}: {document: DocumentModel}) => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      updateDocumentContentJsonForUserFlowRunDocumentForm:
        UpdateDocumentContentJsonForUserFlowRunDocument.toForm(
          updateDocumentContentJsonForUserFlowRunDocumentForm,
        ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateDocumentContentJsonForUserFlowRunDocumentMutation.mutation,
    variables: {
      updateDocumentContentJsonForUserFlowRunDocumentForm:
        UpdateDocumentContentJsonForUserFlowRunDocument.toMutation(
          form.values.updateDocumentContentJsonForUserFlowRunDocumentForm,
        ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateDocumentContentJsonForUserFlowRunDocumentMutation.mutation = gql`
  mutation useUpdateDocumentContentJsonForUserFlowRunDocumentMutation(
    $updateDocumentContentJsonForUserFlowRunDocumentForm: UpdateDocumentContentJsonForUserFlowRunDocumentForm!
  ) {
    response: updateDocumentContentJsonForUserFlowRunDocument(
      updateDocumentContentJsonForUserFlowRunDocumentForm: $updateDocumentContentJsonForUserFlowRunDocumentForm
    ) {
      ${gql.errors}
      document {
        id
      }
    }
  }
`;

export default useUpdateDocumentContentJsonForUserFlowRunDocumentMutation;
