// Libraries
import {createStackNavigator, createSwitchWrapperNavigator} from '@supermove/navigation';

// App
import AuthenticatedWrapper from 'modules/App/components/AuthenticatedWrapper';
import RouterWrapper from 'modules/App/components/RouterWrapper';
import ExpiredPage from 'modules/Authentication/Expired/ExpiredPage';
import LoginPage from 'modules/Authentication/Login/LoginPage';
import UnauthenticatedPage from 'modules/Authentication/Unauthenticated/UnauthenticatedPage';
import DocumentFlowRunExpiredPage from 'modules/DocumentFlowRun/DocumentFlowRunExpiredPage';
import DocumentFlowRunPage from 'modules/DocumentFlowRun/DocumentFlowRunPage';
import DocumentFlowRunStepPage from 'modules/DocumentFlowRun/DocumentFlowRunStepPage/DocumentFlowRunStepPage';
import DocumentFlowRunSuccessPage from 'modules/DocumentFlowRun/DocumentFlowRunSuccessPage/DocumentFlowRunSuccessPage';
import HomePage from 'modules/Home/HomePage';
import AccessPointInventoryPage from 'modules/Inventory/AccessPoint/AccessPointInventoryPage';
import FinishInventoryPage from 'modules/Inventory/Finish/FinishInventoryPage';
import HelpInventoryPage from 'modules/Inventory/Help/HelpInventoryPage';
import NewInventoryReviewInventoryPage from 'modules/Inventory/InventoryReview/New/NewInventoryReviewInventoryPage';
import PrepareInventoryPage from 'modules/Inventory/Prepare/PrepareInventoryPage';
import ListRoomInventoryPage from 'modules/Inventory/Room/List/ListRoomInventoryPage';
import NewRoomInventoryPage from 'modules/Inventory/Room/New/NewRoomInventoryPage';
import ShowRoomInventoryPage from 'modules/Inventory/Room/Show/ShowRoomInventoryPage';
import StartInventoryPage from 'modules/Inventory/Start/StartInventoryPage';

const Authentication = createStackNavigator(
  {
    LoginPage: {
      path: '',
      screen: LoginPage,
    },
    ExpiredPage: {
      path: 'expired',
      screen: ExpiredPage,
    },
    UnauthenticatedPage: {
      path: 'unauthenticated',
      screen: UnauthenticatedPage,
    },
  },
  {
    initialRouteName: 'LoginPage',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const App = createStackNavigator(
  {
    HomePage: {
      path: '',
      screen: HomePage,
    },
    StartInventoryPage: {
      path: 'inventories/:uuid/start',
      screen: StartInventoryPage,
    },
    PrepareInventoryPage: {
      path: 'inventories/:uuid/prepare',
      screen: PrepareInventoryPage,
    },
    NewRoomInventoryPage: {
      path: 'inventories/:uuid/rooms/new',
      screen: NewRoomInventoryPage,
    },
    ShowRoomInventoryPage: {
      path: 'inventories/:uuid/rooms/:roomUuid',
      screen: ShowRoomInventoryPage,
    },
    ListRoomInventoryPage: {
      path: 'inventories/:uuid/rooms',
      screen: ListRoomInventoryPage,
    },
    AccessPointInventoryPage: {
      path: 'inventories/:uuid/access-point',
      screen: AccessPointInventoryPage,
    },
    FinishInventoryPage: {
      path: 'inventories/:uuid/finish',
      screen: FinishInventoryPage,
    },
    NewInventoryReviewInventoryPage: {
      path: 'inventories/:uuid/review',
      screen: NewInventoryReviewInventoryPage,
    },
    HelpInventoryPage: {
      path: 'inventories/:uuid/help',
      screen: HelpInventoryPage,
    },
  },
  {
    initialRouteName: 'HomePage',
    headerMode: 'none',
    backBehavior: 'history',
  },
);

const Authenticated = createSwitchWrapperNavigator(
  {
    App: {
      path: '',
      screen: App,
    },
  },
  {
    initialRouteName: 'App',
    backBehavior: 'history',
    wrapper: AuthenticatedWrapper,
  },
);

const Public = createStackNavigator(
  {
    DocumentFlowRunPage: {
      path: ':slug/document-flows/:userFlowRunUuid',
      screen: DocumentFlowRunPage,
    },
    DocumentFlowRunStepPage: {
      path: ':slug/document-flows/:userFlowRunUuid/documents/:userFlowRunStepUuid',
      screen: DocumentFlowRunStepPage,
    },
    DocumentFlowRunSuccessPage: {
      path: ':slug/document-flows/:userFlowRunUuid/success',
      screen: DocumentFlowRunSuccessPage,
    },
    DocumentFlowRunExpiredPage: {
      path: ':slug/document-flows/:userFlowRunUuid/expired',
      screen: DocumentFlowRunExpiredPage,
    },
  },
  {
    headerMode: 'none',
    backBehavior: 'history',
  },
);

// A SwitchWrapperNavigator is a SwitchNavigator with an extra component rendered
// which has access to the `navigation` prop.
const Router = createSwitchWrapperNavigator(
  {
    Authentication: {
      path: 'login',
      screen: Authentication,
    },
    Authenticated: {
      path: '',
      screen: Authenticated,
    },
    Public: {
      path: '0',
      screen: Public,
    },
  },
  {
    initialRouteName: 'Authenticated',
    backBehavior: 'history',
    wrapper: RouterWrapper,
  },
);

export default Router;
