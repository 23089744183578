// Libraries
import React from 'react';

// Supermove
import {DataLoader, Emoji, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {ResponsiveType, useResponsive, useState} from '@supermove/hooks';
import {UserFlowRunStepModel} from '@supermove/models';
import {Environment} from '@supermove/sdk';
import {Document} from '@supermove/utils';

// App
import CollapsibleContent from '@shared/design/components/CollapsibleContent';
import DocumentV2ByCategory from '@shared/modules/Document/components/DocumentV2ByCategory';

const PaddingContainer = Styled.View<{responsive: ResponsiveType}>`
  padding: ${({responsive}) => (responsive.mobile ? '16px' : '24px')};
`;

const openPrintDialog = async ({
  url,
  setIsLoading,
}: {
  url: string;
  setIsLoading: (loading: boolean) => void;
}) => {
  setIsLoading(true);
  try {
    const filename = 'document.pdf';
    url = `${Environment.getAppUrl()}${url}`;
    const pdf = await Document.generatePDFFromUrl({filename, url});
    Document.printFile({file: pdf});
  } catch (error) {
    console.error({error});
  } finally {
    setIsLoading(false);
  }
};

const DocumentFlowRunSuccessStep = ({
  userFlowRunStepUuid,
  defaultCollapsed,
}: {
  userFlowRunStepUuid: string;
  defaultCollapsed: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const responsive = useResponsive();
  const {loading, data} = useQuery<{userFlowRunStep: UserFlowRunStepModel}>(
    DocumentFlowRunSuccessStep.query,
    {
      variables: {
        userFlowRunStepUuid,
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  return (
    <DataLoader loading={loading} data={data}>
      {({loadedData}) => {
        const {userFlowRunStep} = loadedData;
        const {document} = userFlowRunStep;
        return (
          <CollapsibleContent
            TitleIconComponent={<Emoji style={{paddingVertical: 6}} name={'pencil'} />}
            title={document.displayName}
            primaryActionText={!responsive.mobile ? 'Print' : undefined}
            handlePrimaryAction={() => {
              openPrintDialog({
                url: `/0/${document.organization.slug}/documents/v2/${document.uuid}`,
                setIsLoading,
              });
            }}
            defaultCollapsed={defaultCollapsed}
            isSubmittingPrimaryAction={isLoading}
          >
            <PaddingContainer responsive={responsive}>
              <DocumentV2ByCategory document={document} />
            </PaddingContainer>
          </CollapsibleContent>
        );
      }}
    </DataLoader>
  );
};

DocumentFlowRunSuccessStep.query = gql`
  ${DocumentV2ByCategory.fragment}

  query DocumentFlowRunSuccessStep($userFlowRunStepUuid: String!) {
    ${gql.query}
    userFlowRunStep(uuid: $userFlowRunStepUuid) {
      id
      uuid
      document {
        id
        uuid
        displayName
        organization {
          id
          slug
        }
        ...DocumentV2ByCategory
      }
    }
  }
`;

export default DocumentFlowRunSuccessStep;
