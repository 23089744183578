// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {ResponsiveType, useResponsive} from '@supermove/hooks';
import {DocumentModel} from '@supermove/models';

// App
import DocumentFlowRunStepHeader from 'modules/DocumentFlowRun/DocumentFlowRunStepPage/components/DocumentFlowRunStepHeader';
import CustomerDocumentV2Sign from 'modules/DocumentFlowRun/DocumentV2/CustomerDocumentV2Sign';

const getSubtitleText = ({
  responsive,
  submitButtonText,
}: {
  responsive: ResponsiveType;
  submitButtonText: string;
}) =>
  `Please read through the document below and press "${submitButtonText}".${
    responsive.mobile ? ' ' : '\n'
  }You will be able to print a copy of the document on the last page.`;

const DocumentFlowRunStepDocument = ({
  document,
  isComplete,
  currIndex,
  stepCount,
  submitButtonText,
  beforeSubmit,
  onSubmit,
}: {
  document: DocumentModel;
  isComplete: boolean;
  currIndex: number;
  stepCount: number;
  submitButtonText: string;
  onSubmit: () => void;
  beforeSubmit: (beforeSubmit: () => void) => void;
}) => {
  const responsive = useResponsive();

  return (
    <CustomerDocumentV2Sign
      documentUuid={document.uuid}
      onSubmit={onSubmit}
      HeaderComponent={DocumentFlowRunStepHeader}
      headerComponentProps={{
        isComplete,
        currIndex,
        stepCount,
        title: 'Review Document',
        subtitle: getSubtitleText({responsive, submitButtonText}),
        documentDisplayName: document.displayName,
      }}
      submitButtonText={submitButtonText}
      beforeSubmit={beforeSubmit}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFlowRunStepDocument.fragment = gql`
  fragment DocumentFlowRunStepDocument on Document {
    id
    uuid
    displayName
  }
`;

export default DocumentFlowRunStepDocument;
