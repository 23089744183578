// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Icon} from '@supermove/components';

// App
import Callout from '@shared/design/components/Callout';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';

const FileUploadCallouts = ({
  isConnected,
  failedUploadFiles,
}: {
  isConnected: boolean;
  failedUploadFiles: string[];
}) => {
  const errorText = `Some files failed to upload:\n${failedUploadFiles.map((filename: string) => `  \u2022 ${filename}\n`).join('')}`;

  return (
    <React.Fragment>
      {!isConnected && (
        <React.Fragment>
          <Space height={16} />
          <Callout
            icon={Icon.ExclamationTriangle}
            text={"You're currently offline. Uploading will be available once you're online."}
          />
        </React.Fragment>
      )}
      {_.some(failedUploadFiles) && (
        <React.Fragment>
          <Space height={16} />
          <WarningCallout text={errorText} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FileUploadCallouts;
