// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useQuery, useRef, useState, useInternet} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CustomerInventoryForm from '@shared/modules/Inventory/forms/CustomerInventoryForm';
import useUpdateCustomerInventoryMutation from '@shared/modules/Inventory/hooks/useUpdateCustomerInventoryMutation';
import Field from 'components/Field';
import HelpBubble from 'components/HelpBubble';
import LargeButton from 'components/LargeButton';
import Line from 'components/Line';
import PhotoCollection from 'components/PhotoCollection';
import ProgressBar from 'components/ProgressBar';
import AccessPointInventoryTutorialModal from 'modules/Inventory/Help/InventoryTutorial/AccessPointInventoryTutorialModal';
import FileUploadCallouts from 'modules/Inventory/components/FileUploadCallouts';
import InventoryHeader from 'modules/Inventory/components/InventoryHeader';

const Container = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
`;

const PaddedContainer = Styled.View`
  padding-horizontal: 16px;
`;

const InstructionText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const SelectionChoiceContainer = Styled.View`
  flex-direction: row;
  margin-bottom: 10px;
`;

const SelectionChoiceButton = Styled.Touchable`
  flex: 0.5;
  height: 42px;
  border-width: 1px;
  border-color: ${(props) => ((props as any).isSelected ? colors.orange.status : colors.gray.border)};
  border-radius: 8px;
  justify-content: center;
  background-color: ${(props) => ((props as any).isSelected ? colors.orange.accent : colors.white)};
`;

const SelectionChoiceText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.black};
  padding-horizontal: 12px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const MinutesContainer = Styled.View`
  flex: 0.5;
`;

const Selection = ({children, onPress, isSelected}: any) => {
  return (
    <SelectionChoiceContainer>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <SelectionChoiceButton onPress={onPress} isSelected={isSelected}>
        <SelectionChoiceText>{children}</SelectionChoiceText>
      </SelectionChoiceButton>
      <Space width={8} />
    </SelectionChoiceContainer>
  );
};

const AccessPointInventoryPageContent = ({inventory, refetch}: any) => {
  const {navigator, params} = useNavigation();
  const {isConnected} = useInternet();
  const [failedUploadFiles, setFailedUploadFiles] = useState<string[]>([]);
  const customerInventoryForm = CustomerInventoryForm.edit(inventory);
  const {form, submitting, handleSubmit} = useUpdateCustomerInventoryMutation({
    customerInventoryForm,
    onSuccess: () => {
      navigator.navigate('FinishInventoryPage', {uuid: params.uuid});
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const {
    accessPointHasElevator,
    accessPointHasStairs,
    accessPointHasNone,
    accessPointWalkTime,
    accessPointWalkTimeDetails,
    isUploadingPhoto,
  } = form.values.customerInventoryForm;

  const walkwayDetailsComplete =
    accessPointHasElevator || accessPointHasStairs || accessPointHasNone;
  const walktimeDetailsComplete =
    accessPointWalkTime === 'CUSTOM' ? !!accessPointWalkTimeDetails : !!accessPointWalkTime;

  const accessPointTutorialModal = useModal();
  const fileViewerHandleOpenRef = useRef(() => {});

  return (
    <Container>
      <ScrollView>
        <InventoryHeader title={'Virtual Walkthrough'} inventory={inventory} />
        <ProgressBar progressAmount={2 / 3} />
        <Space height={16} />
        <PaddedContainer>
          <HelpBubble>
            {'You are almost done!\n' +
              'Last step is to capture the access point. ' +
              'This information is helpful for our estimator ' +
              'team to come up with a more accurate quote.'}
          </HelpBubble>
          <FileUploadCallouts isConnected={isConnected} failedUploadFiles={failedUploadFiles} />
        </PaddedContainer>
        <Space height={12} />
        <Line />
        <Space height={16} />
        <PaddedContainer>
          <InstructionText>
            {'1. Do you have elevators or stairs in your building?'}
          </InstructionText>
          <Space height={12} />
          <Selection
            onPress={() => {
              form.setFieldValue(
                'customerInventoryForm.accessPointHasElevator',
                !accessPointHasElevator,
              );
              form.setFieldValue('customerInventoryForm.accessPointHasNone', false);
            }}
            isSelected={accessPointHasElevator}
          >
            Elevator
          </Selection>
          <Selection
            onPress={() => {
              form.setFieldValue(
                'customerInventoryForm.accessPointHasStairs',
                !accessPointHasStairs,
              );
              form.setFieldValue('customerInventoryForm.accessPointHasNone', false);
            }}
            isSelected={accessPointHasStairs}
          >
            Stairs
          </Selection>
          <Selection
            onPress={() => {
              form.setFieldValue('customerInventoryForm.accessPointHasElevator', false);
              form.setFieldValue('customerInventoryForm.accessPointHasStairs', false);
              form.setFieldValue('customerInventoryForm.accessPointHasNone', true);
            }}
            isSelected={accessPointHasNone}
          >
            None
          </Selection>
          <Space height={8} />
          <InstructionText>
            {'2. How long is the walk from where the truck ' +
              'will park to the entrance of the building?'}
          </InstructionText>
          <Space height={12} />
          <Selection
            onPress={() => {
              form.setFieldValue('customerInventoryForm.accessPointWalkTime', 'SECONDS_30');
              form.setFieldValue('customerInventoryForm.accessPointWalkTimeDetails', '');
            }}
            isSelected={accessPointWalkTime === `SECONDS_30`}
          >
            0 - 30 secs
          </Selection>
          <Selection
            onPress={() => {
              form.setFieldValue('customerInventoryForm.accessPointWalkTime', 'SECONDS_120');
              form.setFieldValue('customerInventoryForm.accessPointWalkTimeDetails', '');
            }}
            isSelected={accessPointWalkTime === `SECONDS_120`}
          >
            30 secs - 2 min
          </Selection>
          <Row>
            <SelectionChoiceButton
              onPress={() => {
                form.setFieldValue('customerInventoryForm.accessPointWalkTime', 'CUSTOM');
                if (!accessPointWalkTimeDetails) {
                  form.setFieldValue('customerInventoryForm.accessPointWalkTimeDetails', '');
                }
              }}
              // @ts-expect-error TS(2769): No overload matches this call.
              isSelected={accessPointWalkTime === `CUSTOM`}
            >
              <SelectionChoiceText>2+ min</SelectionChoiceText>
            </SelectionChoiceButton>
            <Space width={8} />
            {accessPointWalkTime === 'CUSTOM' && (
              <MinutesContainer>
                <Field
                  {...form}
                  name={'customerInventoryForm.accessPointWalkTimeDetails'}
                  input={{
                    placeholder: 'Enter minutes',
                    style: {
                      borderRadius: 8,
                      borderWidth: 1,
                      borderColor: colors.gray.border,
                      height: 42,
                      fontWeight: '500',
                      backgroundColor: colors.white,
                    },
                  }}
                />
              </MinutesContainer>
            )}
          </Row>
          <Space height={18} />
          <InstructionText>{'3. Please take photos of your access point.'}</InstructionText>
          <Space height={8} />
          <PhotoCollection
            room={inventory.accessPointRoom}
            onUploadStart={() => form.setFieldValue('customerInventoryForm.isUploadingPhoto', true)}
            onUploadSuccess={() =>
              form.setFieldValue('customerInventoryForm.isUploadingPhoto', false)
            }
            onUploadError={(filename: string) => {
              setFailedUploadFiles([...failedUploadFiles, filename]);
              form.setFieldValue('customerRoomForm.isUploadingPhoto', false);
            }}
            onAddPhotoPress={(fileViewerHandleOpen: any) => {
              if (inventory.project.hasCompletedInventoryTutorial) {
                fileViewerHandleOpen();
              } else {
                fileViewerHandleOpenRef.current = fileViewerHandleOpen;
                accessPointTutorialModal.handleOpen();
              }
            }}
          />
          <Space height={24} />
          <LargeButton
            disabled={
              submitting || !walkwayDetailsComplete || !walktimeDetailsComplete || isUploadingPhoto
            }
            onPress={handleSubmit}
          >
            <LargeButton.Text color={colors.gray.background}>
              {isUploadingPhoto ? 'Processing upload...' : 'Submit'}
            </LargeButton.Text>
          </LargeButton>
          <Space height={32} />
        </PaddedContainer>
      </ScrollView>
      <AccessPointInventoryTutorialModal
        isOpen={accessPointTutorialModal.isOpen}
        onClose={() => {
          fileViewerHandleOpenRef.current();
          accessPointTutorialModal.handleClose();
          refetch();
        }}
      />
    </Container>
  );
};

const AccessPointInventoryPage = () => {
  const {params} = useNavigation();
  const {loading, data, refetch} = useQuery(AccessPointInventoryPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: params.uuid,
      inventoryTutorialKind: 'ACCESS_POINT',
    },
  });

  return (
    <Loading loading={loading}>
      {() => <AccessPointInventoryPageContent inventory={data.inventory} refetch={refetch} />}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AccessPointInventoryPage.query = gql`
  ${CustomerInventoryForm.edit.fragment}
  ${PhotoCollection.fragment}
  ${InventoryHeader.fragment}

  query AccessPointInventoryPage($uuid: String!, $inventoryTutorialKind: String!) {
    ${gql.query}

    inventory(uuid: $uuid) {
      id
      project {
        id
        hasCompletedInventoryTutorial(kind: $inventoryTutorialKind)
      }
      accessPointRoom {
        id
        ...PhotoCollection
      }
      ...CustomerInventoryForm_edit
      ...InventoryHeader
    }
  }
`;

export default AccessPointInventoryPage;
