/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigation, useQuery, useRef, useInternet, useState} from '@supermove/hooks';
import {UserModel, RoomModel} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import CustomerRoomForm from '@shared/modules/Inventory/forms/CustomerRoomForm';
import useUpdateCustomerRoomMutation from '@shared/modules/Inventory/hooks/useUpdateCustomerRoomMutation';
import CloseButton from 'components/CloseButton';
import Line from 'components/Line';
import ModalScreenHeader from 'components/ModalScreenHeader';
import ClosetInventoryTutorialModal from 'modules/Inventory/Help/InventoryTutorial/ClosetInventoryTutorialModal';
import RoomClosetPhotos from 'modules/Inventory/Room/Show/components/RoomClosetPhotos';
import RoomConfirmDiscardModal from 'modules/Inventory/Room/Show/components/RoomConfirmDiscardModal';
import RoomNotes from 'modules/Inventory/Room/Show/components/RoomNotes';
import RoomRecording from 'modules/Inventory/Room/Show/components/RoomRecording';
import FileUploadCallouts from 'modules/Inventory/components/FileUploadCallouts';

const ContentContainer = Styled(ScrollView)`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const AddRoomButtonContainer = Styled.View`
  padding-horizontal: 16px;
`;

const AddRoomButton = Styled.Touchable<{disabled: boolean}>`
  background-color: ${({disabled}) => (disabled ? colors.gray.border : colors.blue.interactive)};
  padding: 20px;
  border-radius: 100px;
  align-items: center;
`;

const AddRoomButtonText = Styled.H6`
  color: ${colors.white};
  ${fontWeight(700)}
`;

const ModalIconContainer = Styled.View`
  flex-direction: row;
`;

const DiscardIconContainer = Styled.Touchable`
  padding-right: 12px;
  padding-left: 4px;
`;

const CloseIconContainer = Styled.Touchable`
  padding-right: 2px;
  padding-left: 12px;
`;

const CalloutsContainer = Styled.View`
  padding-horizontal: 16px;
`;

const ShowRoomInventoryContent = ({
  room,
  viewer,
  refetch,
}: {
  room: RoomModel;
  viewer?: UserModel;
  refetch: () => void;
}) => {
  const {params, navigator} = useNavigation();
  const {isConnected} = useInternet();
  const [failedUploadFiles, setFailedUploadFiles] = useState<string[]>([]);
  const customerRoomForm = CustomerRoomForm.edit(room);
  const {form, submitting, handleSubmit} = useUpdateCustomerRoomMutation({
    customerRoomForm,
    onSuccess: () => {
      if (params.action === 'update') {
        navigator.navigate('ListRoomInventoryPage', {uuid: params.uuid});
      } else {
        navigator.navigate('NewRoomInventoryPage', {uuid: params.uuid});
      }
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const confirmDiscardModal = useModal();
  const closetTutorialModal = useModal();
  const fileViewerHandleOpenRef = useRef(() => {});
  const {isUploadingPhoto, isUploadingVideo} = form.values.customerRoomForm;
  const onUploadError = (filename: string) => {
    setFailedUploadFiles([...failedUploadFiles, filename]);
    form.setFieldValue('customerRoomForm.isUploadingPhoto', false);
  };

  return (
    <ContentContainer>
      <ModalScreenHeader
        // Custom rooms do not have a room type so we use the room name
        title={room.roomType?.name || room.name}
        right={
          params.action === 'update' ? (
            <ModalIconContainer>
              <DiscardIconContainer disabled={submitting} onPress={confirmDiscardModal.handleOpen}>
                <Icon color={colors.black} size={20} source={Icon.Trash} />
              </DiscardIconContainer>
              <Space width={8} />
              <CloseIconContainer disabled={submitting} onPress={() => navigator.goBack()}>
                <Icon color={colors.black} size={20} source={Icon.Times} />
              </CloseIconContainer>
            </ModalIconContainer>
          ) : (
            <CloseButton
              onPress={() => {
                if (!submitting) {
                  confirmDiscardModal.handleOpen();
                }
              }}
            />
          )
        }
      />
      <CalloutsContainer>
        <FileUploadCallouts isConnected={isConnected} failedUploadFiles={failedUploadFiles} />
      </CalloutsContainer>
      <RoomRecording
        room={room}
        onUploadStart={() => form.setFieldValue('customerRoomForm.isUploadingVideo', true)}
        onUploadSuccess={() => form.setFieldValue('customerRoomForm.isUploadingVideo', false)}
        onUploadError={onUploadError}
      />
      <Line />
      <RoomClosetPhotos
        form={form}
        room={room}
        viewer={viewer}
        closetTutorialModal={closetTutorialModal}
        fileViewerHandleOpenRef={fileViewerHandleOpenRef}
        onUploadStart={() => form.setFieldValue('customerRoomForm.isUploadingPhoto', true)}
        onUploadSuccess={() => form.setFieldValue('customerRoomForm.isUploadingPhoto', false)}
        onUploadError={onUploadError}
      />
      <Line />
      <RoomNotes form={form} />
      <Space height={24} />
      <AddRoomButtonContainer>
        <AddRoomButton
          // @ts-expect-error TS(2769): No overload matches this call.
          onPress={handleSubmit}
          disabled={isUploadingPhoto || isUploadingVideo || submitting}
        >
          <AddRoomButtonText>
            {isUploadingPhoto || isUploadingVideo
              ? 'Processing upload...'
              : params.action === 'update'
                ? 'Save'
                : 'Submit'}
          </AddRoomButtonText>
        </AddRoomButton>
      </AddRoomButtonContainer>
      <Space height={32} />
      <RoomConfirmDiscardModal
        room={room}
        isOpen={confirmDiscardModal.isOpen}
        onClose={confirmDiscardModal.handleClose}
      />
      <ClosetInventoryTutorialModal
        isOpen={closetTutorialModal.isOpen}
        onClose={() => {
          fileViewerHandleOpenRef.current();
          closetTutorialModal.handleClose();
          refetch();
        }}
      />
    </ContentContainer>
  );
};

const ShowRoomInventoryPage = () => {
  const {params} = useNavigation();

  const {loading, data, refetch} = useQuery(ShowRoomInventoryPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      roomUuid: params.roomUuid,
      inventoryTutorialKind: 'ADD_CLOSET',
    },
  });

  return (
    <Loading loading={loading}>
      {() => {
        return <ShowRoomInventoryContent room={data.room} refetch={refetch} />;
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ShowRoomInventoryPage.query = gql`
  ${CustomerRoomForm.edit.fragment}
  ${RoomClosetPhotos.fragment}
  ${RoomRecording.fragment}

  query ShowRoomInventoryPage($roomUuid: String!, $inventoryTutorialKind: String!) {
    ${gql.query}
    room(uuid: $roomUuid) {
      id
      name
      roomType {
        id
        name
      }
      inventory {
        id
      }
      ...CustomerRoomForm_edit
      ...RoomClosetPhotos
      ...RoomRecording
    }
  }
`;

export default ShowRoomInventoryPage;
