// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {DocumentModel} from '@supermove/models';

// App
import CompleteUserFlowRunStepDocumentForm, {
  CompleteUserFlowRunStepDocumentFormToFormType,
} from '@shared/modules/Document/forms/CompleteUserFlowRunStepDocumentForm';

const useCompleteUserFlowRunStepDocumentMutation = ({
  completeUserFlowRunStepDocumentForm,
  onSuccess,
  onError,
}: {
  completeUserFlowRunStepDocumentForm: CompleteUserFlowRunStepDocumentFormToFormType;
  onSuccess: ({document}: {document: DocumentModel}) => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      completeUserFlowRunStepDocumentForm: CompleteUserFlowRunStepDocumentForm.toForm(
        completeUserFlowRunStepDocumentForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCompleteUserFlowRunStepDocumentMutation.mutation,
    variables: {
      completeUserFlowRunStepDocumentForm: CompleteUserFlowRunStepDocumentForm.toMutation(
        form.values.completeUserFlowRunStepDocumentForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCompleteUserFlowRunStepDocumentMutation.mutation = gql`
  mutation useCompleteUserFlowRunStepDocumentMutation($completeUserFlowRunStepDocumentForm: CompleteUserFlowRunStepDocumentForm!) {
    response: completeUserFlowRunStepDocument(completeUserFlowRunStepDocumentForm: $completeUserFlowRunStepDocumentForm) {
      ${gql.errors}
      document {
        id
        isCompleted
      }
    }
  }
`;

export default useCompleteUserFlowRunStepDocumentMutation;
