// Supermove
import {gql} from '@supermove/graphql';
import {RoomModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface CustomerRoomFormType {
  roomId: string;
  customerNotes: string;
  canSkipPhotos: boolean;
  // Private
  isUploadingPhoto: boolean;
  isUploadingVideo: boolean;
}

export interface CustomerRoomFormTypeWrapper {
  customerRoomForm: CustomerRoomFormType;
}

interface CustomerRoomFormMutationType {
  roomId: string;
  customerNotes: string;
  canSkipPhotos: boolean;
}

const edit = withFragment(
  (room: RoomModel): CustomerRoomFormType => ({
    roomId: room.id,
    customerNotes: room.customerNotes,
    canSkipPhotos: false,
    // Private
    isUploadingPhoto: false,
    isUploadingVideo: false,
  }),
  gql`
    fragment CustomerRoomForm_edit on Room {
      id
      customerNotes
    }
  `,
);

const toForm = ({
  roomId,
  customerNotes,
  canSkipPhotos,
  isUploadingPhoto,
  isUploadingVideo,
}: CustomerRoomFormType) => ({
  roomId,
  customerNotes,
  canSkipPhotos,

  // Private
  isUploadingPhoto,
  isUploadingVideo,
});

const toMutation = ({roomId, customerNotes, canSkipPhotos}: CustomerRoomFormMutationType) => ({
  roomId,
  customerNotes,
  canSkipPhotos,
});

const CustomerRoomForm = {
  edit,
  toForm,
  toMutation,
};

export default CustomerRoomForm;
