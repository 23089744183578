// Libraries
import React from 'react';

// Supermove
import {PageLoader} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {UserFlowRunStepModel} from '@supermove/models';

// App
import DocumentFlowRunStepPageContent from 'modules/DocumentFlowRun/DocumentFlowRunStepPage/components/DocumentFlowRunStepPageContent';

const DocumentFlowRunStepPage = () => {
  const {params} = useNavigation();

  const {loading, data} = useQuery<{userFlowRunStep: UserFlowRunStepModel}>(
    DocumentFlowRunStepPage.query,
    {
      fetchPolicy: 'cache-and-network',
      variables: {userFlowRunStepUuid: params.userFlowRunStepUuid},
    },
  );

  return (
    <PageLoader loading={loading} data={data}>
      {({loadedData}) => {
        return <DocumentFlowRunStepPageContent userFlowRunStep={loadedData.userFlowRunStep} />;
      }}
    </PageLoader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFlowRunStepPage.query = gql`
  ${DocumentFlowRunStepPageContent.fragment}
  query DocumentFlowRunStepPage($userFlowRunStepUuid: String!) {
    ${gql.query}
    userFlowRunStep(uuid: $userFlowRunStepUuid) {
      id
      ...DocumentFlowRunStepPageContentFragment
    }
  }
`;

export default DocumentFlowRunStepPage;
