// Libraries
import React from 'react';

// Supermove
import {MaintenancePage, SupermoveApp} from '@supermove/app';
import {StatusBar, UpdateChecker} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Environment} from '@supermove/sdk';
import {colors} from '@supermove/styles';

// App
import ErrorModal from 'modules/App/components/ErrorModal';

import {AppInfo} from '../config';

const CustomerAppContent = ({children}: {children: React.ReactNode}) => {
  const name = 'Customer';
  const version = AppInfo.getVersion();
  const buildNumber = AppInfo.getBuildNumber();
  const {data} = useQuery(CustomerApp.query, {fetchPolicy: 'cache-first'});

  return (
    <ErrorModal
      trigger={({handleOpen}: any) => (
        <SupermoveApp
          name={name}
          version={version}
          buildNumber={buildNumber}
          showOfflineOverlay={false}
          onError={(error) => {
            handleOpen();
          }}
          isAuthenticated={data?.isAuthenticated}
          viewer={data?.viewer}
        >
          <StatusBar barStyle={'light-content'} backgroundColor={colors.black} />
          <UpdateChecker name={name} buildNumber={buildNumber} pollInterval={1000 * 30}>
            {({isVisible}) => null}
          </UpdateChecker>
          {children}
        </SupermoveApp>
      )}
    />
  );
};

const CustomerApp = ({router: Router}: {router: any}) => {
  const showMaintenancePage = Environment.get('SHOW_MAINTENANCE_PAGE');

  if (showMaintenancePage) {
    return <MaintenancePage />;
  }

  return (
    <CustomerAppContent>
      <Router />
    </CustomerAppContent>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerApp.query = gql`
  ${SupermoveApp.fragment}

  query CustomerApp {
    ${gql.query}
    isAuthenticated
    viewer {
      id
      ...SupermoveApp
    }
  }
`;

export default CustomerApp;
