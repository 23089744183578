// App

export interface UpdateDocumentContentJsonForUserFlowRunDocumentFormType {
  documentUuid: string;
}

const _new = ({documentUuid}: UpdateDocumentContentJsonForUserFlowRunDocumentFormType) => {
  return {
    documentUuid,
  };
};

const toForm = ({documentUuid}: UpdateDocumentContentJsonForUserFlowRunDocumentFormType) => {
  return {
    documentUuid,
  };
};

const toMutation = ({documentUuid}: UpdateDocumentContentJsonForUserFlowRunDocumentFormType) => {
  return {
    documentUuid,
  };
};

const UpdateDocumentContentJsonForUserFlowRunDocument = {
  new: _new,
  toForm,
  toMutation,
};

export default UpdateDocumentContentJsonForUserFlowRunDocument;
