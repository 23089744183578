// Libraries
import React from 'react';

// App
import {Emoji, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Title = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Subtitle = Styled.Text`
  ${Typography.Responsive.Subheading}
  line-height: 24px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmojiText = Styled.Text`
  ${Typography.Responsive.Heading2}
  margin-right: 5px;
`;

const Name = Styled.Text`
  ${Typography.Responsive.Label}
`;

const DocumentFlowRunStepHeader = ({
  isComplete,
  currIndex,
  stepCount,
  subtitle,
  title,
  documentDisplayName,
}: {
  isComplete: boolean;
  currIndex: number;
  stepCount: number;
  subtitle: string;
  title: string;
  documentDisplayName: string;
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Space height={60} />
      <Container>
        {!isComplete && (
          <Label responsive={responsive}>{`Step ${currIndex + 1} of ${stepCount}`}</Label>
        )}
        <Title responsive={responsive}>{title}</Title>
        <Subtitle responsive={responsive}>{subtitle}</Subtitle>
      </Container>
      <Space height={30} />
      <Row>
        <Emoji component={EmojiText} name={'pencil'} />
        <Name responsive={responsive}>{documentDisplayName}</Name>
      </Row>
      <Space height={10} />
    </React.Fragment>
  );
};

export default DocumentFlowRunStepHeader;
