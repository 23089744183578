// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigation} from '@supermove/hooks';
import {UserFlowRunStepModel} from '@supermove/models';
import {Datetime} from '@supermove/utils';

// App
import DocumentFlowRunStepDocument from 'modules/DocumentFlowRun/DocumentFlowRunStepPage/components/DocumentFlowRunStepDocument';

const Container = Styled.View`
  flex: 1;
`;

const DocumentFlowRunStepPageContent = ({
  userFlowRunStep,
}: {
  userFlowRunStep: UserFlowRunStepModel;
}) => {
  const {navigator, params} = useNavigation();
  const {userFlowRun, document} = userFlowRunStep;

  const redirectToExpiredPage = () => {
    navigator.push('DocumentFlowRunExpiredPage', {
      slug: params.slug,
      userFlowRunUuid: params.userFlowRunUuid,
    });
  };

  if (userFlowRun.project.isCancelled || userFlowRun.isExpired) {
    redirectToExpiredPage();
    return <></>;
  }

  return (
    <Container>
      <DocumentFlowRunStepDocument
        document={document}
        isComplete={userFlowRun.isCompleted}
        currIndex={userFlowRunStep.index}
        stepCount={userFlowRun.stepCount}
        submitButtonText={userFlowRunStep.userFlowStep.buttonText}
        onSubmit={() => {
          navigator.push('DocumentFlowRunPage', params);
        }}
        beforeSubmit={(handleSubmit: () => void) => {
          if (
            userFlowRun.expirationDatetime &&
            Datetime.fromDatetime(userFlowRun.expirationDatetime) <= Datetime.now
          ) {
            redirectToExpiredPage();
          } else {
            handleSubmit();
          }
        }}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFlowRunStepPageContent.fragment = gql`
  ${DocumentFlowRunStepDocument.fragment}

  fragment DocumentFlowRunStepPageContentFragment on UserFlowRunStep {
    id
    uuid
    index
    userFlowStep {
      id
      buttonText
    }
    userFlowRun {
      id
      uuid
      isExpired
      isCompleted
      expirationDatetime
      stepCount
      project {
        id
        isCancelled
      }
    }
    document {
      id
      ...DocumentFlowRunStepDocument
    }
  }
`;

export default DocumentFlowRunStepPageContent;
