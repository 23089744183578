// Supermove
import {gql} from '@supermove/graphql';
import {DocumentModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import DocumentForm, {DocumentFormToFormType} from '@shared/modules/Document/forms/DocumentForm';

export interface CompleteUserFlowRunStepDocumentFormToFormType {
  step: string;
  documentForm: DocumentFormToFormType;
  numberOfErrors: number;
}

const _new = withFragment(
  ({document, step}: {document: DocumentModel; step: string}) => {
    return {
      // TODO: (zharkyn) The step is still needed for document to render.
      // I haven't figured out why yet. I will remove the step.
      step,
      documentForm: DocumentForm.edit(document),

      // Private
      numberOfErrors: 0,
    };
  },
  gql`
    ${DocumentForm.edit.fragment}

    fragment CompleteUserFlowRunStepDocumentForm_new on Document {
      id
      ...DocumentForm_edit
    }
  `,
);

const toForm = ({
  step,
  documentForm,
  numberOfErrors,
}: CompleteUserFlowRunStepDocumentFormToFormType) => {
  return {
    step,
    documentForm: DocumentForm.toForm(documentForm),

    // Private
    numberOfErrors,
  };
};

const toMutation = ({step, documentForm}: {step: string; documentForm: DocumentFormToFormType}) => {
  return {
    documentForm: DocumentForm.toMutation(documentForm),
  };
};

const CompleteUserFlowRunStepDocumentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default CompleteUserFlowRunStepDocumentForm;
